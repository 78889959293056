@import url('https://fonts.googleapis.com/css?family=Shizuru');
@import url('https://fonts.googleapis.com/css?family=Major+Mono+Display');

html{
  color: white;
  font-size: 3rem;
  text-align: center;
  padding: 5%;
}

.button-container{
  position: relative;
  height: 90vh;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.text{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: 'Major Mono Display';
}

.container{
  position: relative;
  font-family: 'Shizuru';
}

span{
  color: white;
}